import React from 'react';

import Layout from '../../components/Layout';
import Service from '../../components/Service';

import Image from '../../../assets/images/services/hotfoil.jpg';

const col1 = [
  'High quality foil printed labels on rolls',
  'Wide range of print colours including metallics',
  'Multiple Spot Colours',
  'Huge variety of base materials for various applications',
  'Laminating / Sprocketing / Numbering / Sheeting available'
];

const col2 = [
  'Ideal for overprinting your labels with metallic foils',
  'Suitable for printing many barcode symbologies',
  'Label sizes from 4mm up to 225 x 225mm',
  "Special products include Window stickers, 'Split-Lam' and Embossed labels"
];

const Hotfoil = () => {
  const title = 'Hotfoil Labels';

  const metaDescription =
    'High quality Hotfoil printed labels from Calf Hey Design. Rolls, sheets, fan folded with a host of options for improving any label. Overprinting service.';
  const metaKeywords =
    'hotfoil, labels, labelling, print, colour, gold, foil, substrate, overprinting, barcodes, numbering, sprocketing, plain, printed, embossed, domed, numbered, window stickers, quality, rolls, sheets, fan folded';

  return (
    <Layout title={title} description={metaDescription} keywords={metaKeywords}>
      <Service
        tag="Calf Hey Design"
        title={title}
        description="<p>Hot Foil printing is our most commonly used process due to its high quality, cost effectivness and ability
            to produce labels in any quantity from 10 labels to 1,000,000+ labels. </p><p>We have <strong>over 6000 stock cutters!</strong> </p><p>With a large range
            of options available we can customise labels to your specific details. </p>"
        image={Image}
        col1={col1}
        col2={col2}
        artwork="<strong>ARTWORK FOR HOTFOIL</strong> - vector artwork is preferred with all text converted and supplied as a .eps, .ai or high resolution .pdf"
      />
    </Layout>
  );
};

export default Hotfoil;
